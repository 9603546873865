<template>
  <div class="w-full text-center" v-if="isLoading">
    <asom-icon icon="spinner" class="animate-spin" />
  </div>
  <div v-else class="space-y-10 smrt_stations">
    <asom-card>
      <div class="sm:w-1/2">
        <asom-form-field>
          <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:flex-1">Line</label>
          <asom-input-select
            :objectModelValue="false" 
            v-model="selectedLineId" 
            :options="lineOptions"
            placeholder="Select a line"
          />
        </asom-form-field>
      </div>
    </asom-card>
    <asom-client-table 
      :columns="[ 'no', 'stationName', 'formattedShortCode', 'zones', 'group', 'line' ]"
      :data="proceededStations"
      :sortableColumns="[ 'stationName', 'formattedShortCode', 'zones', 'group', 'line' ]"
      tableClasses="tbl_smrtStation"
    >
      <template v-slot:header_stationName>Name</template>
      <template v-slot:header_formattedShortCode>Station Code</template>
      <template v-slot:no="scopedSlots">
        {{scopedSlots.rowIndex + 1}}
      </template>
      <template v-slot:formattedShortCode="scopedSlots">
        <div class="ShortCodeBlock">
          <div
            v-for="(sc, i) in scopedSlots.rowData.shortCode"
            :class="[ 'ShortCode', `ShortCode--${sc}`]"
            :key="i"
          >
            {{sc}}
          </div>
        </div>
      </template>
    </asom-client-table>
  </div>
</template>

<script>
import get from 'lodash.get'
import sortBy from 'lodash.sortby';
import { getStationList, getLineList, getZoneList, getGroupList } from '../services/smrtResource.service'

export default {
  name: 'Users',
  data() {
    return {
      lines: [],
      groups: [],
      zones: [],
      stations: [],
      isLoading: false,
      error: '',
      selectedLineId: null,
    }
  },
  computed: {
    proceededStations() {
      let result = this.stations
      if (this.selectedLineId)
        result = result.filter(({ zoneStations }) => zoneStations.some(({ lineId }) => lineId === this.selectedLineId ))
      return sortBy(
        result.map((station) => {
          return {
            stationName: station.stationName,
            formattedShortCode: this.getStationFormattedShortCodes(station),
            shortCode: this.getStationShortCodes(station),
            zones: this.getStationZoneNames(station),
            group: this.getStationGroupNames(station),
            line: this.getStationLineNames(station),
          }
        }),
        [ 'group', 'formattedShoftCode' ]
      );
    },
    lineOptions() {
      return this.lines.map(line => ({ label: line.lineName, value: line.lineId }))
    }
  },
  mounted() {
    this.loadSMRTData()
  },
  methods: {
    getStationShortCodes({ zoneStations = []}) {
      const codes = zoneStations.map(zs => zs.code);
      return [ ...new Set(codes) ].sort();
    },
    getStationFormattedShortCodes(station) {
      const codes = this.getStationShortCodes(station)
      const formattedCodes = codes.map(code => {
        const numCode = code.substr(-2); // e.g 'C1' for CC1, '10' for CC10
        if (Number.isNaN(Number.parseInt(numCode))) {
          return code.substr(0, code.length - 2) + '0' + numCode; // CC1 => CC01
        }
        return code;
      });
      return formattedCodes;
    },
    getStationZones({ zoneStations = [] }) {
      return this.zones.filter(z => zoneStations.some(zs => zs.zoneId === z.zoneId))
    },
    getStationGroups(station) {
      const groupIds = this.getStationZones(station).map(z => z.groupId)
      return this.groups.filter(g => groupIds.includes(g.groupId))
    },
    getStationLines(station) {
      const lineIds = this.getStationGroups(station).map(z => z.lineId);
      return this.lines.filter(g => lineIds.includes(g.lineId))
    },
    getStationZoneNames(station) {
      const zoneNames = [ ...new Set(this.getStationZones(station).map(z => z.zoneName))];
      return zoneNames.join(', ')
    },
    getStationGroupNames(station) {
      const stationGroupNames = [ ...new Set(this.getStationGroups(station).map(z => z.groupName))];
      return stationGroupNames.join(', ')
    },
    getStationLineNames(station) {
      const lineNames = this.getStationLines(station).map(z => {
        switch (z.lineName) {
          case 'South Line':
          case 'North Line':
            return 'North-South Line'
          case 'East Line':
          case 'West Line':
            return 'East-West Line'
          default: return z.lineName
        }
      });
      return [ ...new Set(lineNames)].join(',');
    },
    async loadSMRTData() {
      this.isLoading = true
      
      const lineResp = await getLineList({ skip: 0, take: 100 })
      if (lineResp.success) {
        this.lines = get(lineResp, 'payload.list', [])
      } else {
        this.error = lineResp.payload
        this.isLoading = false
        return;
      }

      const zoneResp = await getZoneList({ skip: 0, take: 100 })
      if (zoneResp.success) {
        this.zones = get(zoneResp, 'payload.list', [])
      } else {
        this.error = zoneResp.payload
        this.isLoading = false
        return;
      }

      const groupResp = await getGroupList({ skip: 0, take: 100 })
      if (groupResp.success) {
        this.groups = get(groupResp, 'payload.list', [])
      } else {
        this.error = groupResp.payload
        this.isLoading = false
        return;
      }

      const stationResp = await getStationList({ skip: 0, take: 100 })
      if (stationResp.success) {
        this.stations = get(stationResp, 'payload.list', []);
      } else {
        this.error = stationResp.payload
        this.isLoading = false
        return;
      }
      this.isLoading = false
    }
  }
}
</script>
<style scoped>
.ShortCodeBlock {
  @apply flex
}

.ShortCode {
  @apply py-1 px-3 text-xs font-semibold bg-gray-400 text-white tracking-wider
}


/* @media screen and (max-width: 768px) {
  .ShortCodeBlock {
    @apply block;
  }

  .ShortCode {
    @apply rounded;
  }
} */

[class*='ShortCode--CC'],
[class*='ShortCode--CE'] {
  @apply bg-yellow-400
}

[class*='ShortCode--NS'] {
  @apply bg-red-400
}

[class*='ShortCode--EW'],
[class*='ShortCode--CG'] {
  @apply bg-green-400
}

[class*='ShortCode--DT'] {
  @apply bg-blue-400
}

[class*='ShortCode--NE'] {
  @apply bg-purple-400
}

[class*='ShortCode--TE'] {
  background-color: #9D5B25;
}

.ShortCode:first-child {
  @apply rounded-l-md
}

.ShortCode:last-child {
  @apply rounded-r-md
}

</style>
<style lang="scss">
.smrt_stations .AsomCard__Body{
  @apply rounded-br-md rounded-bl-md
}
.tbl_smrtStation{ 
  thead th:first-child > div{
    @apply justify-center
  }
  tbody td:first-child{
    @apply text-center
  }
  tbody td:nth-child(2){
    @apply font-semibold text-gray-900
  }
}
</style>
